<template>
  <loader v-bind="{ loading }" text="Loading Work Orders">
    <columns>
      <column class="is-narrow" v-if="!$root.isPassiveUser()">
        <action-button @click="openWorkOrderTransfer" left-icon="wrench" class="is-rounded">Tools</action-button>
      </column>
      <column>
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for work orders...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="workOrder in workOrders.data" :key="workOrder.uuid">
            <columns>
              <column>
                <router-link class="has-text-weight-bold is-block" :to="{
                    name: 'work-order-manager',
                    params: {
                      workOrder: workOrder.uuid
                    }
                  }">
                  #{{ workOrder.number }}
                  {{ workOrder.reference }}
                </router-link>
                <small class="has-text-grey">Updated {{ workOrder.updated_at | asCalendar }}</small>
              </column>
              <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                <span v-tooltip="'The team assigned to this Work Order'">
                  <icon icon="people-arrows" class="has-text-grey mr-1"/>
                  <span>{{ workOrder.team.name }}</span>
                </span>
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <circular-progress-bar 
                  tooltip="Current progress of the Work Order"
                  :total="workOrder.inspections.length"
                  :total-complete="workOrder.inspections.map(inspection => inspection.closed_off_at).length"
                  backgroundColor="#212127"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-center">
                <priority-indicator 
                  :priority="workOrder.priority"
                />
              </column>
              <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                <outcome-tag 
                    :status="workOrder.status"
                />
              </column>
            </columns>
          </div>
        </div>
        <pager v-if="workOrders.data.length" :pageable="workOrders" context="Work Order" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="workOrders.data.length"
          heading="There are no work orders available for display."
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { transferLocationWorkOrders } from '@/modals'
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true,
    filters: {
        search: ''
    },
    workOrders: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadWorkOrders()
  },

  methods: {
    loadWorkOrders() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.workOrders = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'location-work-orders',
        params: {
            location: this.$route.params.location
        },
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        params: {
            location: this.$route.params.location
        },
        name: 'location-work-orders',
      });
    },
    openWorkOrderTransfer() {
      transferLocationWorkOrders(this.location).then(transferred => {
        if(transferred) {
          this.$store.commit('location/clearLocation')
          this.$store.dispatch('location/load', transferred)
          this.$router.push({
          name: 'location-work-orders',
          params: {
              location: transferred
          },})
          this.$toast.success('Work Orders Transferred.')
        }
      })
    }
  },

  computed: {
    ...mapGetters('location', ['location'])
  },

  watch: {
    '$route': 'loadWorkOrders'
  }

}
</script>